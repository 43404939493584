 <div class="container-fluid p-0">
   <div class="row m-0">
     <div class="col-12 p-0">
       <div class="login-card login-dark">
         <div>
           <div><a class="logo" [routerLink]="['/dashboard/admin-dashboard']">
               <img class="img-fluid for-light" height="50" width="80" src="assets/images/logo/logo.png" alt="looginpage">
               <!-- <img
                 class="img-fluid for-dark" src="assets/images/logo/logo-3.png" alt="looginpage"> -->
                </a> 
           </div>
           <div class="login-main">
             <form class="theme-form" [formGroup]="loginForm">
               <h3>Sign in to account</h3>
               <p>Enter your Employee ID & password to login</p>
               <div class="form-group">
                 <label class="col-form-label">Employee ID</label>
                 <input class="form-control" type="text" required="" placeholder="Employee ID"
                   formControlName="employeeId" [disabled]="isSubmitting">
                 <div *ngIf="loginForm.controls['employeeId'].touched && loginForm.controls['employeeId'].errors?.['required']"
                   class="text text-danger mt-1">
                   Employee ID is required
                 </div>
                 <!-- <div *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['email']"
                   class="text text-danger mt-1">
                   Invalid Email
                 </div> -->
               </div>
               <div class="form-group">
                 <label class="col-form-label">Password</label>
                 <div class="form-input position-relative">
                   <input class="form-control" [type]="show ? 'text' : 'password'" type="password"
                     name="login[password]" required=""  formControlName="password" [disabled]="isSubmitting">
                     <!-- name="login[password]" required="" placeholder="*********" formControlName="password"> -->
                   <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"> </span></div>
                   <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                   <div
                     *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']"
                     class="text text-danger mt-1">
                     Password is required
                   </div>
                 </div>
               </div>
               <div class="form-group mb-0">
                 <div class="checkbox p-0">
                   <input id="checkbox1" type="checkbox">
                   <label class="text-muted" for="checkbox1">Remember password</label>
                 </div>
                 <!-- <a class="link" [routerLink]="['/authentication/forgot-password']">Forgot password?</a> -->
                 <div class="text-end mt-3">
                   <button class="btn btn-primary btn-block w-100" [disabled]="!loginForm.valid || isSubmitting" (click)="login()"
                     type="submit">
                      <ng-container *ngIf="!isSubmitting">Sign in</ng-container>
                      <ng-container *ngIf="isSubmitting">
                          <span class="spinner"></span>Signing in...
                      </ng-container>
                      </button>
                 </div>
               </div>
               <!-- <h4 class="text-muted mt-4 or">Or Sign in with</h4> -->
               <!-- <div class="social mt-4">
                 <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login"
                     target="_blank">
                     <app-feathericon [icon]="'linkedin'" class="txt-linkedin"></app-feathericon>LinkedIn
                   </a><a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                     <app-feathericon [icon]="'twitter'" class="txt-twitter"></app-feathericon>twitter
                   </a>
                   <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                     <app-feathericon [icon]="'facebook'" class="txt-fb"></app-feathericon>facebook
                   </a>
                 </div>
               </div> -->
               <!-- <p class="mt-4 mb-0 text-center">Don't have account?
                 <a class="ms-2" [routerLink]="['/authentication/register-simple']">Create
                   Account
                 </a>
               </p> -->
               <div class="form-group mb-0  text-center">
                 <a class="ms-2" [routerLink]="['/forgot-password']">
                  <u>
                    Forgot Password
                  </u>
                </a>
              </div>
             </form>
             <div>
               <p class="text-center">Version:{{appVerison}}</p>
              </div>
            </div>
         </div>
       </div>
     </div>
   </div>
 </div>
