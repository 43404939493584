import { Component } from '@angular/core';
import { NavservicesService, Menu } from '../../services/nav/navservices.service';
import { LayoutService } from '../../services/layout/layout.service';
import { RoleMappingHelperService } from '../../services/role-mapping/role-mapping-helper.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { RolesAndAccessService } from '../../services/manage-roles-and-access/roles-and-access.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  public menus: any []=  [];
  public mainMenu: boolean = false;
  public menuItem = {}
  public active: boolean = false;
  public screenWidth: number;
  public screenHeight: number;
  private currentUrl: string;
  constructor(private router: Router, public navService: NavservicesService, public layout: LayoutService,  private roleMappingService: RoleMappingHelperService, private roleAndAccessService: RolesAndAccessService) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    const accessCodes = this.roleMappingService.ACCESS_CODES;
  
    // Fetch the subfeatures for 'Reports' module before filtering
    this.roleAndAccessService.getReportsSubFeatures().subscribe((res: any) => {
      const subFeatureNames = res.data || [];
  
      // Now filter the menus using the retrieved subfeatures
      this.menus = this.navService.Nvabarmenu.filter((menu: Menu) => {
        let { mainTitle, moduleName, accessType } = menu;
        if (!accessType) {
          accessType = 'read';
        }
        const feature: any = mainTitle !== 'Home' ? this.roleMappingService.getModuleByName(moduleName) : '';
        const moduleCode = mainTitle !== 'Home' && feature ? this.roleMappingService.getAccessCodeByType(accessType, feature) : '';
  
        if (menu.moduleName === 'Reports') {
          // Check if the user has access to the 'Reports' module
          if (!!feature && accessCodes.includes(moduleCode) && menu.item) {
            if (subFeatureNames.length > 0) {
              menu.item = menu.item.filter((item) => {
                return subFeatureNames.includes(item.subFeatureName);
              });
              return menu.item.length > 0;
            } else {
              return true;
            }
          }
          return false;
        }
  
        return mainTitle === 'Home' || (!!feature && accessCodes.includes(moduleCode));
      });
  
      // Proceed with other initialization tasks after menus are set
      this.currentUrl = window.location.pathname;
      this.highlightMenuOption();
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.currentUrl = this.router.url;
        this.highlightMenuOption();
      });
    });
  }

  toggleMenu(item: Menu) {
    if (!item.active) {
      this.menus.forEach((a: Menu) => {
        if (this.menus.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b: Menu) => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
        return;
      });
    }
    item.active = !item.active;
    if (item.active == true) {
      this.navService.isShow = true;
    } else {
      this.navService.isShow = false;
    }
    if(!item.item && item?.path) {
      this.router.navigate([`${item?.path}`])
    }
  }

  toggle(item: Menu, mainMenu?: Menu) {
    if (!item.active) {
      this.menus.forEach((a: Menu) => {
        a.item?.forEach((child) => {
          if (a.item?.includes(item)) {
            child.active = false;
          }
          if (child.children) {
            child.children.forEach((subChild) => {
              if (child.children?.includes(item)) {
                subChild.active = false;
              }
            })
          }
        })
        return;
      });
    }
    item.active = !item.active;
    if (mainMenu) {
      mainMenu.active = false
      this.navService.isShow = false
    }
  }

  highlightMenuOption() {
    this.menus.forEach((menu) => {
      if(menu?.path?.includes(this.currentUrl)) {
        menu.active = true;
      } else {
        menu.active = false;
      }
    })
  }

}








