import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Subscription } from "rxjs";
import { DummyEncToken } from "src/app/shared/data/component/features-rolemapping/feature";
import {
  IFeatureMapping,
  IFeatureResponse,
} from "src/app/shared/models/feature-mapping.interface";
import {
  IAuthenticationResponseDTO,
  ILoginUserDTO,
} from "src/app/shared/models/user.interface";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { SkanrayToastService } from "src/app/shared/services/common/skanray-toast.service";
import { EncryptDecryptService } from "src/app/shared/services/encryption.service";
import { FeaturesService } from "src/app/shared/services/features.service";
import { RoleMappingHelperService } from "src/app/shared/services/role-mapping/role-mapping-helper.service";
import * as packageJson from "../../../../package.json";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loginForm: FormGroup;
  features$: Subscription;
  appVerison: string = "";
  public isSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private rolemappingHelper: RoleMappingHelperService,
    private featuresService: FeaturesService,
    private encryptDecryptService: EncryptDecryptService,
    private authService: AuthenticationService,
    private toastService: SkanrayToastService
  ) {
    const userData = localStorage.getItem("user");
    if (userData?.length != null) {
      router.navigate(["/dashboard/admin-dashboard"]);
    }

    this.loginForm = this.fb.group({
      employeeId: [, [Validators.required]],
      password: [, Validators.required],
      // employeeId: ["SA1234", [Validators.required]],
      // password: ["Skanray123", Validators.required],
    });
  }

  ngOnInit(): void {
    this.appVerison = packageJson?.version || "";
  }
  showPassword() {
    this.show = !this.show;
  }

  // Simple Login
  login() {
    if (this.loginForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const employeeIderrors: any = this.loginForm?.get("employeeId")?.errors;
      if (employeeIderrors) {
        // Handle specific errors
        console.log("employeeId ", employeeIderrors);
      }

      const passworderrors: any = this.loginForm?.get("password")?.errors;
      if (passworderrors) {
        // Handle specific errors
        console.log("password ", passworderrors);
      }

      if (
        this.loginForm.value["employeeId"] &&
        this.loginForm.value["password"]
      ) {
        let user: ILoginUserDTO = {
          employeeId: this.loginForm.value["employeeId"],
          password: this.loginForm.value["password"],
        };

        this.authService.loginByEmployeeID(user).subscribe(
          (res: IAuthenticationResponseDTO) => {
            localStorage.setItem(
              btoa("accessToken"),
              btoa(res.data.accessToken)
            );
            localStorage.setItem(btoa("roleId"), btoa(res.data.roleId));
            localStorage.setItem(btoa("login_id"), btoa(res.data.id));
            localStorage.setItem(
              btoa("isSalesEngineer"),
              btoa(res.data.isSalesEngineer)
            );
            this.authService.setLoginDetailsData(res?.data);
            this.getFeatures();
            this.setAccessCodes();
            this.setUserDetails(res.data?.name, res.data?.role, res.data.id);
            this.toastService.showToaster(res);
            this.isSubmitting = false;
            this.router.navigate(["/dashboard/admin-dashboard"]);
          },
          (err) => {
            this.isSubmitting = false;
            this.toastService.showToaster(err);
          }
        );
      }
    }
  }

  setUserDetails(username: string, role: string, userId: string) {
    this.authService.setUserDetails(username, role);
    localStorage.setItem(btoa("username"), btoa(username));
    localStorage.setItem(btoa("role"), btoa(role));
    localStorage.setItem(btoa("userId"), btoa(userId));
  }

  getFeatures() {
    this.features$ = this.featuresService
      .getFeatures()
      .subscribe((res: IFeatureMapping[]) => {
        this.setFeatures(res);
      });
  }

  private setFeatures(features: IFeatureMapping[]) {
    this.rolemappingHelper.FEATURES = features;
    // console.log('this.rolemappingHelper.FEATURES ', this.rolemappingHelper.FEATURES);
  }

  private setAccessCodes() {
    const helper = new JwtHelperService();
    const storedEncodedToken = localStorage.getItem(btoa("accessToken"));
    if (storedEncodedToken) {
      // Decode the access token
      const accessToken: any = atob(storedEncodedToken);
      const decodedToken = helper.decodeToken(accessToken);

      // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmRlZWVjZjFjMmVlZWZiNzNkOWU5YyIsImFjY2Vzc0NvZGVzIjpbInNrYW42NDg5Mjc1NjM4MDAwMCIsInNrYW42NDg5Mjc1NjM4MDAwMSIsInNrYW42NDg5Mjc1NjM4MDAwMiIsInNrYW42NDg5Mjc1NjM4MDAwMyIsInNrYW44NzQ1NjM4NzU2MDAwMCIsInNrYW44NzQ1NjM4NzU2MDAwMSIsInNrYW44NzQ1NjM4NzU2MDAwMiIsInNrYW44NzQ1NjM4NzU2MDAwMyIsInNrYW40MTQ3MjkzMDY1MDAwMCIsInNrYW40MTQ3MjkzMDY1MDAwMSIsInNrYW40MTQ3MjkzMDY1MDAwMiIsInNrYW40MTQ3MjkzMDY1MDAwMyIsInNrYW41MTQ4MDE5MzQyMDAwMCIsInNrYW41MTQ4MDE5MzQyMDAwMSIsInNrYW41MTQ4MDE5MzQyMDAwMiIsInNrYW41MTQ4MDE5MzQyMDAwMyJdLCJpYXQiOjE3MDE3MDM3MzgsImV4cCI6MTcwNDI5NTczOH0.3kCCARY9297FM_4dC5E1hBGDpruy1KTlmRdefmYWJF4`
      // console.log('decodedToken ', decodedToken);
      this.rolemappingHelper.ACCESS_CODES = decodedToken?.accessCodes;
    }
  }

  setData(user: any) {
    const dummyEncToken = DummyEncToken;
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem(btoa("accessToken"), btoa(dummyEncToken));
    this.setAccessCodes();
    this.router.navigate(["/dashboard/admin-dashboard"]);
  }
}
