import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/component/layout/content/content.component';
import { dashData } from './shared/routes/routes';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { SkanrayFeaturesResolver } from './shared/resolvers/skanray-features.resolver';
import { WebenquiryformComponent } from './webenquiryform/webenquiryform.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { WebenquiryEcommerceformComponent } from './webenquiry-ecommerceform/webenquiry-ecommerceform.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'webenquiry',
    component: WebenquiryformComponent
  },
  {
    path: 'webenquiry-ecommerce',
    component: WebenquiryEcommerceformComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword/:id',
    component: ForgotPasswordComponent
  },
  // {
  //   path: '**', 
  //   redirectTo: '/auth/login',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'manage-company',
  //   component: CompanyComponent,
  //   data: {
  //     title: "Manage company",
  //     breadcrumb: "Manage company",
  //   }
  // },
  // {
  //   path: 'manage-company/add',
  //   component: CompanyAddEditComponent,
  //   data: {
  //     title: "Add company",
  //     breadcrumb: "Add company",
  //   }
  // },
  // {
  //   path: 'manage-company/edit/:id',
  //   component: CompanyAddEditComponent,
  //   data: {
  //     title: "Edit company",
  //     breadcrumb: "Edit company",
  //   }
  // },
  // {
  //   path: 'manage-admin',
  //   component: AdminComponent,
  //   data: {
  //     title: "Manage admin",
  //     breadcrumb: "Manage admin",
  //   }
  // },
  // {
  //   path: 'manage-admin/add',
  //   component: AdminAddEditComponent,
  //   data: {
  //     title: "Add admin",
  //     breadcrumb: "Add admin",
  //   }
  // },
  // {
  //   path: 'manage-admin/edit/:id',
  //   component: AdminAddEditComponent,
  //   data: {
  //     title: "Edit admin",
  //     breadcrumb: "Edit admin",
  //   }
  // },
  {
    path: '',
    component: ContentComponent,
    children: dashData,
    canActivate: [AuthGuard],
    resolve: { features: SkanrayFeaturesResolver }
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
