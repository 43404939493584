import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class GeoService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getAllGeo(page: any, limit: any, search?:any) {
    const apiUrl = `${environment.serverEndPoint}/geo/list?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  exportList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/geo/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getGeo(id: any) {
    const apiUrl = `${environment.serverEndPoint}/geo/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateGeo(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/geo/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createGeo(data: any) {
    const apiUrl = `${environment.serverEndPoint}/geo/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownGeoList() {
    console.log('service called^^^^');
    const apiUrl = `${environment.serverEndPoint}/geo/dropdown`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownGeoListWithoutAuth(companyId : string) {
    const apiUrl = `${environment.serverEndPoint}/geo/no-auth/dropdown?companyId=${companyId}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
