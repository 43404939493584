import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IForm,
  IFormmControl,
  IValidator,
} from "../../data/component/models-interfaces/form.interface";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { CompanyService } from "../../services/manage-company/company.service";
// import { Router } from "@angular/router";
import { GeoService } from "../../services/manage-territory/geo.service";
import { CountryService } from "../../services/manage-territory/country.service";
import { StateService } from "../../services/manage-territory/state.service";
import { RegionService } from "../../services/manage-territory/region.service";
import { CustomerTypeService } from "../../services/manage-customer/customer-type.service";
import { DistrictService } from "../../services/manage-territory/district.service";
import { BranchService } from "../../services/manage-branch/branch";
import { SpecialityService } from "../../services/manage-customer/speciality.service";
import { CustomerService } from "../../services/manage-customer/customer.service";
import * as countriesList from "countries-list";
import { CompetitorService } from "../../services/manage-product/competitor.service";
import { CategoryService } from "../../services/manage-product/category.service";
import { SegmentService } from "../../services/manage-product/segement.service";
import { SubSytemService } from "../../services/manage-product/subsSystem.service";
import { CurrencyService } from "../../services/manage-currency/currency.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
const countryCodes: string[] = Object.keys(countriesList.countries)?.map(
  (code) => (countriesList.countries as any)[code].phone
);

export default countryCodes;

@Component({
  selector: "app-re-useable-form",
  templateUrl: "./re-useable-form.component.html",
  styleUrls: ["./re-useable-form.component.scss"],
})
export class ReUseableFormComponent implements OnInit {
  @Input() form!: IForm;
  @Input() editableFormData: any;
  // @Input() setDataOnAddProduct: any;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter<any>();
  setDataOnAddProduct: any;
  @Input() searchDataFromParent(searchData: any) {
    // Process the search data as needed
    if (searchData) {
      this.setFormValues(searchData);
    }
    console.log("Search data received in child component:", searchData);
   
  }
  dynamicFormGroup!: FormGroup;
  myForm!: FormGroup;
  telePhoneForm!: FormGroup;
  faxForm!: FormGroup;
  checkBoxForm: FormGroup;
  disbaledTerritory = false;
  toSearch = "";
  countryCodeDropDownList: any;
  currencyDropDownList: any;
  isProductForm = false;
  radioList = [{ _id: "", competitorTitle: "" }];

  specialityTypeDropDownList = [{ _id: "", specialityName: "" }];
  companyDropDownList = [{ _id: "", name: "" }];
  segmentDropDownList = [{ _id: "", segmentName: "" }];
  subSystemDropDownList = [{ _id: null, subSystemName: "" }];
  productTypeDropDownList = [{ _id: null, companyName: "" }];
  categoryDropDownList = [{ _id: null, categoryTitle: "" }];
  getDropDownList = [];
  tempBranchList = [
    { id: "1", branchName: "Hyd" },
    { id: "2", branchName: "Pune" },
    { id: "3", branchName: "Mumbai" },
  ];

  customerTypeDropDownList = [{ _id: "", name: "" }];
  customerDropDownList = [{ _id: "", customerName: "" }];
  isLoading = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo'],
    ],
    toolbarPosition: 'top',
  }
  constructor(
    private fb: FormBuilder,
    // private router: Router,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private geoService: GeoService,
    private stateService: StateService,
    private regionService: RegionService,
    private companyService: CompanyService,
    private districtService: DistrictService,
    private branchService: BranchService,
    private specialityService: SpecialityService,
    private customerTypeService: CustomerTypeService,
    private competitorService: CompetitorService,
    private customerService: CustomerService,
    private categoryService: CategoryService,
    private segmentService: SegmentService,
    private subSytemService: SubSytemService
  ) {
    this.dynamicFormGroup = this.fb.group({}, { updateOn: "submit" });
  }

  ngOnInit(): void {
    console.log("editableFormData", this.editableFormData);
    console.log("setDataOnAddProduct", this.setDataOnAddProduct);

    const flattenedArray: (number | string)[] = (
      [] as (number | string)[]
    ).concat(...countryCodes);
    this.countryCodeDropDownList = [...flattenedArray];
    console.log("countryCodes", this.countryCodeDropDownList);

    this.initializeForm();
    // this.setOnSubmitBasedOnRoute(window.location.pathname)
  }
  intializeMobile() {
    this.myForm = this.fb.group({
      countryCode: [null, Validators.required],
      mobileNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      // Validators.pattern(/^[0-9]+$/),
    });
  }

  intializeTelephone() {
    this.telePhoneForm = this.fb.group({
      telePhoneCode: [],
      telePhoneNumber: [],
      // telePhoneNumber: ["", [
      //   Validators.required,
      //   Validators.pattern(/^[0-9]+$/),
      //   Validators.minLength(10),
      //   Validators.maxLength(12)
      // ]]
    });
  }

  intializeFax() {
    this.faxForm = this.fb.group({
      faxCountryCode: [],
      faxNumber: [],
      // faxNumber: ["", [
      //   Validators.required,
      //   Validators.pattern(/^[0-9]+$/),
      //   Validators.minLength(10),
      //   Validators.maxLength(12)
      // ]]
    });
  }

  initalizeCheckBox() {
    this.checkBoxForm = this.fb.group({
      competitor: this.fb.array([]),
    });
  }

  initializeForm() {
    if (this.form?.formControls) {
      let formGroup: any = {};
      this.form?.formControls?.forEach((control: IFormmControl) => {
        let controlValidators: any = [];
        if (control?.validators) {
          control?.validators?.forEach((val: IValidator) => {
            if (val?.validatorName === "required")
              controlValidators.push(Validators.required);
            if (val?.validatorName === "email")
              controlValidators.push(Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));
            if (val?.validatorName === "minlength") {
              controlValidators.push(
                Validators.minLength(val?.minlength as number)
              );
            }
            if (val?.validatorName === "pattern")
              controlValidators.push(
                Validators.pattern(val?.pattern as string)
              );
            if (val?.validatorName === "maxlength") {
              controlValidators.push(
                Validators.maxLength(val?.maxlength as number)
              );
            }
          });
        }

        if (control?.name === "company") {
          this.getCompanyDropDownList();
        }
        if (control?.name === "currency") {
          this.getCurrencyDropDownList();
        }

        if (control?.name?.includes("branch")) {
          this.getBranchDropDownList();
        }
        if (control?.name?.includes("customerId")) {
          this.getcustomerTypeDropDownList();
        }
        if (control?.name?.includes("speciality"))
          this.getspecialityDropDownList();
        if (control?.name?.includes("customer")){
          this.getMangerCustomerDropDownList("ALL");
        }
        if (control?.name?.includes("competitors"))
          this.getCompetitorRadioList();
        if (control?.name?.includes("productCategory")) this.getCategoryList();

        if (control?.type?.includes("datalist")) {
          if (control?.name?.includes("geoName")) this.getGeoDropDownList();
          if (control?.name?.includes("countryName"))
            this.getCountryDropDownList();
          if (control?.name?.includes("regionName"))
            this.getRegionDropDownList();
          if (control?.name?.includes("stateName")) this.getStateDropDownList();
          if (control?.name?.includes("districtName"))
            this.getDistrictDropDownList();
          if (control?.component?.includes("product")) {
            this.getCategoryList();
            this.getSubSystemtList();
            this.productCategoryChange();
          }

          formGroup[control?.name] = [
            control?.value || null,
            controlValidators,
          ];
        } else if (
          control?.type === "radio" &&
          control.name === "isAvailability"
        ) {
          // settinf deafult value on Add
          formGroup[control?.name] = ["Active", controlValidators];
        } else {
          formGroup[control?.name] = [control?.value || "", controlValidators];
        }

        if (
          [
            "regionName",
            "countryName",
            "stateName",
            "districtName",
            "cityName",
          ]?.includes(control?.name) &&
          control?.component
        ) {
          this.disbaledTerritory = true;
          if (control?.name === "districtName") this.toSearch = "district";
        }
      });
      if (formGroup["mobile"]) {
        this.intializeMobile();
        formGroup["mobile"] = this.myForm;
      }
      if (formGroup["competitors"]) {
        this.initalizeCheckBox();
        formGroup["competitors"] = this.checkBoxForm;
      }
      if (formGroup["mobileNumber"]) {
        this.intializeMobile();
        formGroup["mobileNumber"] = this.myForm;
      }
      if (formGroup["fax"]) {
        this.intializeFax();
        formGroup["fax"] = this.faxForm;
      }
      if (formGroup["telephone"]) {
        this.intializeTelephone();
        formGroup["telephone"] = this.telePhoneForm;
      }

      console.log("formGroup", formGroup);
      this.dynamicFormGroup = this.fb.group(formGroup);
      if (this.editableFormData) {
        this.setFormValues(this.editableFormData);
      }
      if (this.disbaledTerritory) this.callHandler(this.toSearch);
    }
  }

  getCheckboxControl(checkboxId: string): FormControl {
    // Define and return a new FormControl for each checkbox
    const control = this.fb.control(false);
    this.dynamicFormGroup.addControl(checkboxId, control);
    return control;
  }

  // Method to check whether a competitor is selected
  isCompetitorSelected(competitorTitle: string): boolean {
    const formArray = this.checkBoxForm.get("competitor") as FormArray;
    const control = formArray.controls.find((c) => c.value === competitorTitle);
    return control ? control.value : false;
  }

  setFormValues(data: { [key: string]: any }) {
    this.form?.formTitle === "Company Form";
    Object.keys(data).forEach((key) => {
      if (key === "competitor") {
        let mobileControl = this.dynamicFormGroup
          .get("competitors")
          ?.get("competitor");
        if (mobileControl) {
          this.getCompetitorRadioList();
          this.onCheckboxChange1(data[key]);
        }
      } else if (key === "mobileNumber" || key === "countryCode") {
        let mobileControl = this.dynamicFormGroup.get("mobile");
        if (mobileControl) {
          this.dynamicFormGroup.get("mobile")?.get(key)?.setValue(data[key]);
        }
      } else if (key === "telePhoneNumber" || key === "telePhoneCode") {
        let mobileControl = this.dynamicFormGroup.get("telephone");
        if (mobileControl) {
          this.dynamicFormGroup.get("telephone")?.get(key)?.setValue(data[key]);
        }
      } else if (key === "faxNumber" || key === "faxCountryCode") {
        let mobileControl = this.dynamicFormGroup.get("fax");
        if (mobileControl) {
          this.dynamicFormGroup.get("fax")?.get(key)?.setValue(data[key]);
        }
      } else if (key === "isAvailability" || key === "isTarget" || key === "isFree" ||  key === 'isAERB') {
        let radioControl = this.dynamicFormGroup.get(key);
        if (radioControl) {
          const setData = key.includes("isAvailability")
            ? data[key] === true
              ? "Active"
              : "Inactive"
            : data[key] === true
            ? "Yes"
            : "No";
          this.dynamicFormGroup.get(key)?.setValue(setData);
        }
      } else if (key === "geo") {
        let geoControl = this.dynamicFormGroup.get("geoName");
        if (geoControl) {
          this.dynamicFormGroup.get("geoName")?.setValue(data?.geo["_id"]);
        }
        this.geoChangeHandler();
      } else if (key === "country" && this.form?.formTitle === "Company Form") {
        let geoControl = this.dynamicFormGroup.get(key);
        if (geoControl) {
          this.dynamicFormGroup.get(key)?.setValue(data[key]);
        }
        const setEvent = {
            target: {
              value: data[key],
            } 
        };
        this.onBlurEvent(key, setEvent);
      } else {
        let formControl = this.dynamicFormGroup.get(key);
        if (formControl) {
          formControl.setValue(data[key]);
          console.log("......set value", formControl);
        }
      }
    });
  }

  onSubmit() {
    this.markFormGroupTouched(this.dynamicFormGroup);
    if (this.dynamicFormGroup.valid) {
      if(this.isProductForm){
        this.dynamicFormGroup?.get('dp')?.enable();
      }

      this.setOnSubmitBasedOnRoute(
        window.location.pathname,
        this.dynamicFormGroup
      );
      console.log(" onsubmit Form values:", this.dynamicFormGroup.value);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  getErrorMessage(control: IFormmControl): string {
    console.log("getErrorMessage", control);

    const myFormControl = this.dynamicFormGroup.get(control?.name);
    let errorMsg = "";

    if (
      control?.name?.toLowerCase()?.includes("mobile") ||
      control?.name?.toLowerCase()?.includes("mobileNumber")
    ) {
      const countryCode = this.myForm.get("countryCode");
      const mobileNum = this.myForm.get("mobileNumber");

      const isCountryCodeInvalid = countryCode?.touched && countryCode.invalid;
      const isMobileNumInvalid = mobileNum?.touched && mobileNum.invalid;

      if (isCountryCodeInvalid && (!mobileNum?.touched || mobileNum.valid)) {
        return (errorMsg = "Country code is required").toLowerCase();
      }

      if (isMobileNumInvalid && (!countryCode?.touched || countryCode.valid)) {
        return (errorMsg = "Mobile Number is required").toLowerCase();
      }

      if (isCountryCodeInvalid && isMobileNumInvalid) {
        return (errorMsg =
          "Country code and Mobile Number are required").toLowerCase();
      }
    }

    control?.validators.forEach((val) => {
      if (myFormControl?.hasError(val?.validatorName as string)) {
        errorMsg = (val?.message as string).toLowerCase();
      }
    });
    return errorMsg;
  }

  setOnSubmitBasedOnRoute(url: any, formData: any) {
    console.log("I am there.....................", url);
    switch (true) {
      case url?.includes("addCompany"):
        const compnayDataToSend: CompanyDetails = this.dynamicFormGroup.value;
        this.emitToParent.emit(compnayDataToSend);
        break;

      case url?.includes("editCompany"):
        const eidtCompanyData: CompanyDetails = this.dynamicFormGroup.value;
        console.log(",..........eidtCompanyData", eidtCompanyData);
        this.emitToParent.emit(eidtCompanyData);
        break;

      case url?.includes("addAdminUser"):
        const addAdminUserData: any = this.dynamicFormGroup.value;
        console.log(",..........addAdminUserData", addAdminUserData);
        this.emitToParent.emit(addAdminUserData);
        break;

      case url?.includes("editAdminUser"):
        const editAdminUserData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editAdminUserData);
        break;

      case url?.includes("addChannel-Partner"):
        const addChannelPartnerData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(addChannelPartnerData);
        break;

      case url?.includes("editChannel-Partner"):
        const editChannelPartnerData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editChannelPartnerData);
        break;

      case url?.includes("geo/add"):
        const geoData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(geoData);
        break;

      case url?.includes("geo/edit"):
        const editGeoData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editGeoData);
        break;

      case url?.includes("country/add"):
        const countryData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(countryData);
        break;

      case url?.includes("country/edit"):
        const editCountryData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editCountryData);
        break;

      case url?.includes("region/add"):
        const regionData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(regionData);
        break;

      case url?.includes("region/edit"):
        const editRegionData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editRegionData);
        break;

      case url?.includes("state/add"):
        const stateData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(stateData);
        break;

      case url?.includes("state/edit"):
        const editStateData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editStateData);
        break;

      case url?.includes("district/add"):
        const districtData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(districtData);
        break;

      case url?.includes("district/edit"):
        const editDistrictData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editDistrictData);
        break;

      case url?.includes("city/add"):
        const cityData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(cityData);
        break;

      case url?.includes("city/edit"):
        const editCityData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(editCityData);
        break;

      case url?.includes("/manage-customer/contact/add"):
        const contactAddData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(contactAddData);
        break;

      case url?.includes("/manage-customer/contact/edit"):
        const contactEditData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(contactEditData);
        break;

      case url?.includes("/manage-customer/speciality/add"):
        const specialityAddData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(specialityAddData);
        break;

      case url?.includes("/manage-customer/speciality/edit"):
        const specialityEditData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(specialityEditData);
        break;

      case url?.includes("/manage-customer/customer-type/add"):
        const customerTypeAddData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(customerTypeAddData);
        break;

      case url?.includes("/manage-customer/customer-type/edit"):
        const customerTypeEditData: any = this.dynamicFormGroup.value;
        this.emitToParent.emit(customerTypeEditData);
        break;

      case url?.includes("/manage-customer/customer-sub-category/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-customer/customer-sub-category/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-currency/currency/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-currency/currency/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/category/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/category/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/competitor/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/competitor/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/sub-system/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/sub-system/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/segment/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/segment/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/products/add"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      case url?.includes("/manage-product/products/edit"):
        this.emitToParent.emit(this.dynamicFormGroup.value);
        break;

      default:
        break;
    }
  }

  onCheckboxChange(e: any) {
    const website: FormArray = this.checkBoxForm.get("competitor") as FormArray;

    if (e.target.checked) {
      website.push(new FormControl(e.target.value));
    } else {
      const index = website.controls.findIndex(
        (x) => x.value === e.target.value
      );
      website.removeAt(index);
    }
  }

  onCheckboxChange1(data: any) {
    const website: FormArray = this.checkBoxForm.get("competitor") as FormArray;

    website.clear(); // Clear existing form controls before updating

    // Loop through the data and add form controls for each item
    data.forEach((item: any) => {
      website.push(new FormControl(item));
    });
  }

  getCompanyDropDownList() {
    this.companyService.getCompaniesDropDownList().subscribe((dropdownData) => {
      console.log("getCompaniesDropDownList data", dropdownData);
      if (dropdownData?.data) {
        this.companyDropDownList = dropdownData?.data;
      }
    });
  }

  getGeoDropDownList() {
    this.toSearch = "geo";
    this.geoService.getDropdownGeoList().subscribe((dropdownData: any) => {
      console.log("geoService data", dropdownData);
      if (dropdownData?.data) {
        this.getDropDownList = dropdownData?.data?.geoDropdown;
      }
    });
  }

  getCountryDropDownList() {
    this.toSearch = "country";
    this.countryService
      .getDropdownCountryList()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCountryList data", dropdownData);
        if (dropdownData?.data) {
          this.getDropDownList = dropdownData?.data?.countryDropdown;
        }
      });
  }

  getDistrictDropDownList() {
    this.toSearch = "city";
    this.districtService
      .getDropdownDistrictList()
      .subscribe((dropdownData: any) => {
        console.log("districtService data", dropdownData);
        if (dropdownData?.data) {
          this.getDropDownList = dropdownData?.data?.districtDropdown;
        }
      });
  }

  getStateDropDownList() {
    this.toSearch = "state";
    this.stateService.getDropdownStateList().subscribe((dropdownData: any) => {
      console.log("getDropdownCountryList data", dropdownData);
      if (dropdownData?.data) {
        this.getDropDownList = dropdownData?.data?.stateDropdown;
      }
    });
  }

  getRegionDropDownList() {
    this.toSearch = "region";
    this.regionService
      .getDropdownRegionList()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCountryList data", dropdownData);
        if (dropdownData?.data) {
          this.getDropDownList = dropdownData?.data?.regionDropdown;
        }
      });
  }

  getcustomerTypeDropDownList() {
    this.customerTypeService
      .getDropdownCustomerType()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCustomerType data", dropdownData);
        if (dropdownData?.data) {
          this.customerTypeDropDownList = dropdownData?.data;
        }
      });
  }

  getMangerCustomerDropDownList(status?: string) {
    this.isLoading = true;
    this.customerService
      .getDropdownCustomere(status)
      .subscribe((dropdownData: any) => {
        console.log("getMangerCustomerDropDownList data", dropdownData);
        this.isLoading = false;
        if (dropdownData?.data) {
          this.customerDropDownList = dropdownData?.data;
        }
      }, err =>{
        this.isLoading = false;
      });
  }

  getBranchDropDownList() {
    this.branchService
      .getDropdownBaseBranchList()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCustomerType data", dropdownData);
        if (dropdownData?.data) {
          this.getDropDownList = dropdownData?.data;
        }
      });
  }

  getspecialityDropDownList() {
    this.specialityService
      .getSpecialityDropDown()
      .subscribe((dropdownData: any) => {
        console.log("specialityService data", dropdownData);
        if (dropdownData?.data) {
          this.specialityTypeDropDownList = dropdownData?.data;
        }
      });
  }

  getCustomerDropDownList() {
    this.specialityService
      .getSpecialityDropDown()
      .subscribe((dropdownData: any) => {
        console.log("specialityService data", dropdownData);
        if (dropdownData?.data) {
          this.specialityTypeDropDownList = dropdownData?.data;
        }
      });
  }

  getCurrencyDropDownList() {
    this.currencyService
      .getDropdownCurrency()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCurrency data", dropdownData);
        if (dropdownData?.data) {
          this.currencyDropDownList = dropdownData?.data;
        }
      });
  }

  getCompetitorRadioList() {
    this.competitorService
      .getDropdownCompetitor()
      .subscribe((dropdownData: any) => {
        console.log("specialityService data", dropdownData);
        if (dropdownData?.data) {
          this.radioList = dropdownData?.data;
        }
      });
  }

  getCategoryList() {
    this.categoryService
      .getDropdownCategory()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownCategory data", dropdownData);
        if (dropdownData?.data) {
          this.companyDropDownList = dropdownData?.data;
          this.categoryDropDownList = dropdownData?.data;
        }
      });
  }

  productCategoryChange(){    
    this.getSegmentList();
  }

  getSegmentList() {
    const categoryID = this.dynamicFormGroup?.get('category')?.value ?? "";
    this.segmentService.getDropdownSegmentById(categoryID).subscribe((dropdownData: any) => {
        this.segmentDropDownList = dropdownData?.data ?? "";
        if(!dropdownData?.data){
          this.dynamicFormGroup.get('segment')?.patchValue("");
        }
    });
    this.dynamicFormGroup.get('segment')?.patchValue("");
  }

  getSubSystemtList() {
    this.subSytemService
      .getDropdownSubSystem()
      .subscribe((dropdownData: any) => {
        console.log("getDropdownSubSystem data", dropdownData);
        if (dropdownData?.data) {
          this.subSystemDropDownList = dropdownData?.data;
        }
      });
  }

  callHandler(key: any) {
    switch (key) {
      case "geo":
        this.geoChangeHandler();
        break;

      case "country":
        this.countryChangeHandler();
        break;

      case "state":
        this.stateChangeHandler();
        break;

      case "region":
        this.regionChangeHandler();
        break;

      case "country":
        this.countryChangeHandler();
        break;

      case "district":
        this.districtChangeHandler();
        break;

      case "city":
        this.cityChangeHandler();
        break;

      default:
        break;
    }
  }

  geoChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("geoName")?.value;
    const controlName = "countryName";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  countryChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("countryName")?.value;
    const controlName = "regionName";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  cityChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("districtName")?.value;
    const controlName = "cityName";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  districtChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("stateName")?.value;
    const controlName = "districtName";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  stateChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("stateName")?.value;
    const controlName = "";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  regionChangeHandler(): void {
    const condition = this.dynamicFormGroup.get("regionName")?.value;
    const controlName = "stateName";
    if (condition) {
      this.dynamicFormGroup.get(controlName)?.enable();
    } else {
      this.dynamicFormGroup.get(controlName)?.disable();
    }
  }

  onBlurEvent(controlName: any, event: any) {
    console.log('controlName', controlName);
    console.log('event.target.value', event.target.value);
    
    if (controlName === "country") {
      const countryValue = event.target.value.toLowerCase();
      this.switchCase(countryValue);
    }
    else if(controlName === "basePrice" && this.dynamicFormGroup.get('dp')){
      this.isProductForm = true;
      this.dynamicFormGroup.get('dp')?.setValue(event.target.value);
      this.dynamicFormGroup?.get('dp')?.disable();
    }
  }

  switchCase(key: any) {
    const countryKey: number = ["india"].includes(key)
      ? 1
      : ["united states", "usa"]?.includes(key)
      ? 2
      : ["united kingdom", "uk"]?.includes(key)
      ? 3
      : ["european union", "europe"]?.includes(key)
      ? 4
      : ["united arab emirates(uae)", "uae"]?.includes(key)
      ? 5
      : 6;

    switch (countryKey) {
      case 1: //"india"
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Tax Number 1 (PAN)";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Tax Number 2 (GSTIN)";
        });
        break;

      case 2: //"united states"
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Tax Number 1 (TIN)";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Tax Number 2 (EIN)";
        });
        break;

      case 3: //"united kingdom":
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Tax Number 1 (UTR)";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Tax Number 2 (NINO)";
        });
        break;

      case 4: //"european union":
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Tax Number 1 (TIN)";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Tax Number 2 (VATIN)";
        });
        break;

      case 5: //"united arab emirates(uae)":
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Tax Number 1 (TIN)";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Tax Number 2 (TRN)";
        });
        break;

      default:
        this.form.formControls.forEach((changeLabel: any) => {
          if (changeLabel.name === "serviceTaxNumber1")
            changeLabel.label = "Service Tax Number 1";
          if (changeLabel.name === "serviceTaxNumber2")
            changeLabel.label = "Service Tax Number 2";
        });
        break;
    }
  }
}
