<ng-container *ngIf="!isVisit && !convertToLeadID">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      OK
    </button>
    <button type="button" class="btn btn-secondary cancelbtn" (click)="onCancel()">
      Cancel
    </button>
  </div>
</ng-container>


<ng-container *ngIf="isVisit">
  <div class="modal-header">
    <h4 class="modal-title">Update Visit Feedback</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="feedBackForm">
      <div class="form-group">
        <label for="message" class="col-sm-4"
          >Feed back</label
        >
        <div>
          <textarea
            type="text"
            placeholder="Search"
            class="form-control"
            formControlName="message"
          ></textarea>
          <small
          *ngIf="
            feedBackForm.get('message')?.touched &&
            feedBackForm.get('message')?.invalid
          "
          class="text-danger"
          >Feedback is required
        </small>
        </div>
      </div>

        <!-- <div class="form-group">
        <label for="status" class="col-sm-4 col-form-label dark-label"
          >Status</label
        >
        <div>
          <ng-select
            [items]="statusDropDownList"
            bindLabel="name"
            bindValue="name"
            formControlName="status"
            placeholder="Select Status"
          ></ng-select>
          <small
          *ngIf="
            feedBackForm.get('status')?.touched &&
            feedBackForm.get('status')?.invalid
          "
          class="text-danger"
          >please select status
        </small>
        </div>

      </div> -->
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary cancelbtn" (click)="onCancel()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
      Submit
    </button>
  </div>
</ng-container>

<ng-container *ngIf="isRemarks && convertToLeadID">
  <div class="modal-header">
    <h4 class="modal-title">Update Remarks</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="remarkForm">
      <div class="form-group">
        <label for="remarks" class="col-sm-4"
          >Remarks</label
        >
        <div>
          <textarea
            type="text"
            placeholder="remarks"
            class="form-control"
            formControlName="remarks"
          ></textarea>
          <small
          *ngIf="
            remarkForm.get('remarks')?.touched &&
            remarkForm.get('remarks')?.invalid
          "
          class="text-danger"
          >Remark is required
        </small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary cancelbtn" (click)="onCancel()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onSubmitRemarks()">
      Submit
    </button>
  </div>
</ng-container>


<ng-container *ngIf="convertToLeadID && !isRemarks">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="convertToLeadForm">
      <div class="form-group row">
      <label for="company" class="col-sm-3 col-form-label">User List</label>
      <div class="col-sm-9">
        <ng-select 
          [items]="userListDropDown"
              bindLabel="label"
              bindValue="value"
          formControlName="userList"
          placeholder="Select"
        ></ng-select>
      </div>
    </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onConvertToLeadConfirm()">
      OK
    </button>
    <button type="button" class="btn btn-secondary cancelbtn" (click)="onCancel()">
      Cancel
    </button>
  </div>
</ng-container>