export const environment = {
    production: false,
    // serverEndPoint: 'https://api-stg.developmentinsideout.com',
    // serverEndPoint: 'http://00.stpl.aa00.00.skanray.net:3000',
    serverEndPoint: 'https://cp2-api.developmentinsideout.com',
    secretKey: 'f1e7d4a9c4bd5eb897df4e5c1a17b4c',
    ivKey:  '953f79d1ecbc9468',
    xAPIKey: 'GGL9dPMG4zWowqZKpOlZiCnkIKd1hOsT',
    uiEndPoint : 'https://cp2.developmentinsideout.com',
    //uiEndPoint : 'http://00.stpl.aa00.00.skanray.net:8002'
};
 
