import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({providedIn: 'root'})
export class RolesAndAccessService {
    constructor(private httpClient: HttpClient) { }


    createRolesAccess(data: any) {
      const apiUrl = `${environment.serverEndPoint}/role-and-access/create`;
      return this.httpClient.post<any>(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    }


      getAllRolesAndAccess(page: number, limit: number) {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/list?page=${page}&limit=${limit}`;
        return this.httpClient.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      getRolesAndAccessById(id: string) {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/${id}`;
        return this.httpClient.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      getParentRoles() {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/dropdown/list`;
        return this.httpClient.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }


      updateRolesAndAccess( id: string, data: any) {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/update/${id}`;
        return this.httpClient.put<any>(apiUrl, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      getReportsSubFeatures() {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/reports/sub-features`;
        return this.httpClient.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      canSetRegionStateOnContractNote() {
        const apiUrl = `${environment.serverEndPoint}/role-and-access/can-set-region-state-on-contract-note`;
        return this.httpClient.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
}