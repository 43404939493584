 <footer class="footer" [ngStyle]="{ 'margin-left' : navService.isDisplay ? '0' : '100px '}"
   [ngStyle]="{ 'margin-left' :navService.isShow ? '365px':'100px'}" style="z-index: 9;">
   <div class="container-fluid">
     <div class="row">
       <div class="col-md-6 footer-copyright">
         <p class="mb-0">Copyright 2025</p> <!--© Cion theme by pixelstrap.-->
       </div>
       <!-- <div class="col-md-6">
         <p class="float-end mb-0">Hand crafted & made with
           <svg class="footer-icon">
             <use href="assets/svg/icon-sprite.svg#heart"></use>
           </svg>
         </p>
       </div> -->
     </div>
   </div>
 </footer>
