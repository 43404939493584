import { Clipboard } from "@angular/cdk/clipboard";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { EncryptDecryptService } from "../../services/encryption.service";
import { environment } from "src/environments/environment";
import { CustomerEnquiryService } from "../../services/manage-web-enquiry/customerEnquiry.service";
import { Observer } from "rxjs";
import { SkanrayToastService } from "../../services/common/skanray-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";

@Component({
  selector: "app-re-useable-table",
  templateUrl: "./re-useable-table.component.html",
  styleUrls: ["./re-useable-table.component.scss"],
})
export class ReUseableTableComponent implements OnInit, OnChanges {
  @Input() tableHeadersArray: any[] = [];
  @Input() tableData: any[] = [];
  @Input() filterData: any;
  @Input() enableViewAction: boolean;
  @Input() displayCopy: boolean;
  @Input() showConvertToLead: boolean;
  @Input() formSubmitedFlag: boolean;
  @Input() isPrimarySecondary: boolean;
  @Input() totalItems: number = 0;
  @Input() isEditable: boolean;
  @Input() openOppFromCustomer: boolean;
  @Input() cityTerritory: boolean = false;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitIsDeleteActive: EventEmitter<any> = new EventEmitter<any>();
  @Output() setIsPrimary: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyURL: EventEmitter<any> = new EventEmitter<any>();
  @Input() page: number = 1;
  @Input() pageSize: number = 10;
  @Input() canEditItem: (item: any) => boolean = () => true;
  @Input() isOrderBooking: boolean = false;
  // page: number = 1;
  // pageSize: number = 10;
  toRoute: string;
  toViewRoute: string;
  isSuperAdmin = false;
  hideAciveDelete = false;
  constructor(private router: Router,
    private encryptDecryptService: EncryptDecryptService,
    private toasterService: SkanrayToastService,
    private modalService: NgbModal,
    private customerEnquiryService: CustomerEnquiryService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    console.log("isPrimarySecondary...................", this.isPrimarySecondary);
    console.log("tableData...................", this.tableData);
    console.log("getPage..................", this.page);
    console.log(
      "tableHeadersArray......................",
      this.tableHeadersArray
    );
    const currentUrl = window.location.pathname;
    console.log("currentUrl....", currentUrl);
    this.setUrl(currentUrl);
    this.hideAciveDelete = currentUrl?.includes("/manage-territory")
      ? true : currentUrl?.includes('manage-web-enquiries') ? true
        : false;
  }

  toActiveDelete(i: any) {
    console.log("toActiveDelete", i);
    this.emitIsDeleteActive.emit({
      dataForActivteDelete: i,
    });
  }

  toChangeIsPrimary_Secondary(i:any){
    this.setIsPrimary.emit({
      setPrimaryData: i.contact,
    });
  }

  setUrl(url: any) {
    console.log("my ...............................................", url);
    // const seturl = url.includes('company') ? 'company'
    switch (true) {
      case url?.includes("company"):
        this.toRoute = "/manage-company/editCompany";
        break;

      case url?.includes("adminUser"):
        this.toRoute = "/admin/editAdminUser";
        break;

      case url?.includes("/manage-channel-partner"):
        this.toRoute = "/manage-channel-partner/editChannel-Partner";
        break;

      case url?.includes("/manage-branch"):
        this.toRoute = "/manage-branch/editBranch";
        break;

      case url?.includes("/manage-territory/geo/list"):
        this.toRoute = "/manage-territory/geo/edit";

        break;

      case url?.includes("/country/list"):
        this.toRoute = "/manage-territory/country/edit";
        break;

      case url?.includes("/region/list"):
        this.toRoute = "/manage-territory/region/edit";
        break;

      case url?.includes("/district/list"):
        this.toRoute = "/manage-territory/district/edit";
        break;

      case url?.includes("/state/list"):
        this.toRoute = "/manage-territory/state/edit";
        break;

      case url?.includes("/city/list"):
        this.toRoute = "/manage-territory/city/edit";
        break;

      case url?.includes("/manage-customer/speciality/list"):
        this.toRoute = "/manage-customer/speciality/edit";
        break;

      case url?.includes("/manage-customer/customers/list"):
        this.toRoute = "/manage-customer/customers/edit";
        break;

      case url?.includes("/manage-customer/contact/list"):
        this.toRoute = "/manage-customer/contact/edit";
        break;

      case url?.includes("/manage-customer/customer-type/list"):
        this.toRoute = "/manage-customer/customer-type/edit";
        break;

      case url?.includes("/manage-customer/customer-sub-category/list"):
        this.toRoute = "/manage-customer/customer-sub-category/edit";
        break;

      case url?.includes("/manage-currency/currency/list"):
        this.toRoute = "/manage-currency/currency/edit";
        break;

      case url?.includes("/manage-product/category/list"):
        this.toRoute = "/manage-product/category/edit";
        break;

      case url?.includes("/manage-product/competitor/list"):
        this.toRoute = "/manage-product/competitor/edit";
        break;

      case url?.includes("/manage-product/sub-system/list"):
        this.toRoute = "/manage-product/sub-system/edit";
        break;

      case url?.includes("/manage-product/segment/list"):
        this.toRoute = "/manage-product/segment/edit";
        break;

      case url?.includes("/manage-product/products/list"):
        this.toRoute = "/manage-product/products/edit";
        break;

      case url?.includes("/manage-user/users/list"):
        this.toRoute = "/manage-user/users/edit";
        this.toViewRoute = "/manage-user/users/details";
        break;

      case url?.includes("/manage-web-enquiries"):
        this.toRoute = "/manage-web-enquiries/editWebEnquiries";
        this.toViewRoute = "/manage-web-enquiries/Customer-Enquiry";
        break;

      default:
        this.toRoute = "";
        break;
    }
    console.log("this.", this.toRoute);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.["formSubmitedFlag"]?.currentValue) {
      this.getFiltereData();
    }
  }

  getFiltereData() {
    const cleanedFilterCriteria = this.removeNullOrUndefined(this.filterData);
    console.log("getFilteredEmployees", cleanedFilterCriteria);

    this.tableData = this.tableData.filter((tData) =>
      Object.entries(cleanedFilterCriteria).every(([key, value]) =>
        (tData[key] as string)?.toLowerCase()?.includes(value.toLowerCase())
      )
    );

    console.log("after tableData", this.tableData);
  }

  removeNullOrUndefined(obj: { [key: string]: any }): { [key: string]: any } {
    const cleanedObject: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        cleanedObject[key] = obj[key];
      }
    }
    return cleanedObject;
  }

  getPage(page: number) {
    console.log("get page", page);
    this.page = page;
    console.log("get page", page);
    this.emitToParent.emit({
      page: page,
      pageSize: this.pageSize,
    });
  }
  getSerialNumber(index: number): number {
    // Calculate the serial number based on the current page and itemsPerPage
    const result = (this.page - 1) * this.pageSize + index + 1;
    return result;
  }


  updateTextToCopy(data: any) {
    const encryptedData = this.encryptDecryptService.encryptObject(data);
    const encodedId = encodeURIComponent(encryptedData);
    const urlLocal = `http://localhost:4200/webenquiry?id=${encodedId}`;
    const url = window.location.origin.includes('localhost') ? urlLocal : `${environment.uiEndPoint}/webenquiry?id=${encodedId}`;
    this.clipboard.copy(url);
  }

  updateEnquiryRemarks(id: string, body:{remarks: string}) {
    this.customerEnquiryService.updateRemarks(id, body).subscribe({
      next: (response: any) => {
        if (response?.status === 200) {
          this.toasterSuccess(response);
        }
        console.log("Data sent successfully:", response);
      },
      error: (error: any) => {
        this.toasterWarning(error);
        console.error("Error sending data:", error);
      },
      complete: () => {
        console.log("Observable completed");
      },
    })
  }


  converToLead(id: string, body?:any) {
    this.customerEnquiryService.convertToLead(id, { 'createdBy':body}).subscribe({
      next: (response: any) => {
        if (response?.status === 200) {
          this.toasterSuccess(response);
        }
        console.log("Data sent successfully:", response);
      },
      error: (error: any) => {
        this.toasterWarning(error);
        console.error("Error sending data:", error);
      },
      complete: () => {
        // This block will be executed when the observable completes (optional)
        console.log("Observable completed");
      },
    } as Observer<any>);
  }

  toasterSuccess(response: any) {
    this.toasterService.showToaster(response);
    this.router.navigate(["/manage-web-enquiries"]);
  }

  toasterWarning(response: any) {
    this.toasterService.showToaster(response);
  }

  onRemark(data: any) {

    const modalRef = this.modalService.open(ConfirmationBoxComponent);
    if (!modalRef) {
      console.error("Modal reference is undefined");
      return;
    }

    modalRef.componentInstance.isRemarks = true;
    modalRef.componentInstance.convertToLeadID = data?._id;

    modalRef.componentInstance.remarkData.subscribe((rData: any) => {
      console.log("RemarksData!...................", rData);
      if (rData) this.updateEnquiryRemarks(data?._id, rData);
      modalRef.close();
    });

    modalRef.result
      .then(() => {
        console.log("Modal closed");
      })
      .catch((error) => {
        console.error("Modal error:", error);
      });
  }


  convertToLeadPopup(data: any) {
    console.log("..webEnquiry....data", data);

    const modalRef = this.modalService.open(ConfirmationBoxComponent);
    if (!modalRef) {
      console.error("Modal reference is undefined");
      return;
    }
    modalRef.componentInstance.convertToLeadID = data?._id;
    modalRef.componentInstance.cityId = data?.cityId;

    modalRef.componentInstance.convertToLeadData.subscribe((cData: any) => {
      console.log("convertToLeadData!...................", cData);
      if (cData) this.converToLead(data?._id, cData?.userList);
      modalRef.close();
    });

    modalRef.result
      .then(() => {
        console.log("Modal closed");
      })
      .catch((error) => {
        console.error("Modal error:", error);
      });
  }


}
