<div *ngIf="dataLoaded" class="bckGround">
  <h2 class="text-center topPadding">ENQUIRY FORM</h2>
  <h4 class="text-center mt-1 mb-2">{{ formSubTittle }}</h4>
</div>

<div *ngIf="dataLoaded" class="container mt-5">
  <form [formGroup]="webEnquiryForm" (ngSubmit)="onSubmit()">
    <div class="form-group row mb-2">
      <label for="sourceOfLead" class="col-md-3 col-form-label dark-label required">Source of Lead</label>
      <div class="col-sm-6">
        <ng-select [items]="sourceOfLeads" bindLabel="display" bindValue="value" formControlName="sourceOfLead"
          placeholder="Select Source Of Lead"></ng-select>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="name" class="col-md-3 col-form-label dark-label required">Customer Name</label>
      <div class="col-sm-6">
        <input type="text" placeholder="Customer Name" class="form-control" formControlName="name" />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="hospitalName" class="col-md-3 col-form-label dark-label">Hospital Name</label>
      <div class="col-sm-6">
        <input type="text" placeholder="Hospital Name" class="form-control" formControlName="hospitalName"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label required">Customer Email</label>
      <div class="col-sm-6">
        <input type="text" placeholder="Customer Email" class="form-control" formControlName="email" />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="product" class="col-md-3 col-form-label dark-label">Product</label>
      <div class="col-sm-6">
        <ng-select [items]="productList" name="product" placeholder="Select" style="background: white;" bindLabel="productName"
          bindValue="_id" formControlName="product">
        </ng-select>
      </div>
    </div>


    <div *ngIf="!isOthers" class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label">GEO</label>
      <div class="col-sm-6">
        <ng-select [items]="geoList" name="geo" placeholder="Select" style="background: white;" bindLabel="geoName"
          bindValue="_id" formControlName="geo" (ngModelChange)="onSelectGeoInLocation()">
        </ng-select>
      </div>
    </div>

    <div *ngIf="!isOthers" class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label">Country</label>
      <div class="col-sm-6">
        <ng-select [items]="countryList" placeholder="Select" style="background: white;" bindLabel="name"
          bindValue="_id" formControlName="country" (ngModelChange)="onSelectCountry()">
        </ng-select>
      </div>
    </div>

    <div *ngIf="!isOthers" class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label">Region</label>
      <div class="col-sm-6">
        <ng-select [items]="regionList" bindLabel="name" bindValue="_id" formControlName="region"
          placeholder="Select Region" (change)="regionChange()"></ng-select>
      </div>
    </div>

    <div *ngIf="!isOthers" class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label">State</label>
      <div class="col-sm-6">
        <ng-select [items]="stateList" bindLabel="name" bindValue="_id" formControlName="state"
          placeholder="Select State" (change)="onSelectState()"></ng-select>
      </div>
    </div>

    <div *ngIf="!isOthers"  class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label">District</label>
      <div class="col-sm-6">
        <ng-select [items]="districtList" bindLabel="name" bindValue="_id" formControlName="district"
          placeholder="Select District" (change)="onSelectdistrict()"></ng-select>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="email" class="col-md-3 col-form-label dark-label required">City</label>
      <div class="col-sm-6">
        <ng-select [items]="filteredCityList.length > 0 ? filteredCityList : cityList" bindLabel="name" bindValue="_id" formControlName="city"
          placeholder="Select City" (change)="onSelectCity()"></ng-select>
      </div>
    </div>

    <div *ngIf="isOthers" class="form-group row mb-2">
      <label for="cityName" class="col-md-3 col-form-label dark-label required">City Name</label>
      <div class="col-sm-6">
        <input type="text" formControlName="cityName" id="cityName" class="form-control"
          placeholder="Enter the city name" />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="customerPhone" class="col-md-3 col-form-label dark-label required">Customer Phone</label>
      <div class="col-sm-1">
        <ng-select [items]="countryCodeDropDownList" formControlName="countryCode" placeholder="Select"></ng-select>
      </div>
      <div class="col-sm-5">
        <input type="text" formControlName="mobileNumber" id="mobileNumber" class="form-control padBtm" maxlength="12"
          placeholder="Enter mobile number" />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="message" class="col-md-3 col-form-label dark-label">Customer Enquiry</label>
      <div class="col-sm-6">
        <textarea placeholder="message" class="form-control" formControlName="message"></textarea>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-offset-3 col-sm-10 text-center">
        <button type="submit" [disabled]="webEnquiryForm.invalid" class="btn btn-primary">
          Submit
        </button>
        <button type="button" (click)="formReset()" class="btn btn-secondary marginLeft cancelbtn">
          Cancel
        </button>
        <!-- <button type="button" [routerLink]="['/auth/login']" class="btn btn-secondary marginLeft">
          Cancel
        </button> -->
      </div>
    </div>
  </form>
</div>

<ng-container *ngIf="!dataLoaded">
  <h3 class="text-center mt-5">
    Sorry, the form has expired. Please contact the admin.
  </h3>
</ng-container>